import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const exportStatisticProductTimes = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.STATISTIC_PRODUCT_TIMES, payload);
    return data
}

export const exportStatisticProductViews = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.STATISTIC_PRODUCT_VIEWS, payload);
    return data
}